import React, { Component } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll'
import './recentViewd.css'
import '../nearby/nearby.css'
import RecentViewdCard from './recentViewdCard'
import { Link } from 'react-router-dom'
import Strings from '../../strings'

class RecentViewd extends Component {
    render() {
        let { doctors, specalities, facilities, user } = this.props
        let recentDoctors = localStorage.getItem("recentDoctors")?.split(',') || []
        let doctorsArray = doctors?.filter(rec => !recentDoctors.includes(rec.id));
        let recentViewd = recentDoctors.map(rec => doctors.find(itm => itm.id === rec))?.filter(rec => rec !== undefined)
        doctorsArray.unshift(...recentViewd)
        doctorsArray = doctorsArray?.slice(0,7)
        return (
            <div className='nearby'>
                <div className='head'>
                    <div className='title'> {Strings.recentViewed}</div>
                    <Link to='/searchView?search_type=doctors' className='showAll'>{Strings.showAll}</Link>

                </div>
                <ScrollContainer className='scrollView' >
                    {doctorsArray.map((item, index) => {
                        return (
                            <div to={'/doctor-profile?key=' + item.id} key={index} style={{ minWidth: '100%' }}>
                                <RecentViewdCard doctor={item} specalities={specalities} facilities={facilities} user={user} />
                            </div>
                        )
                    })}
                </ScrollContainer>
            </div>
        );
    }
}

export default RecentViewd;