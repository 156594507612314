import React, { useEffect, useState } from 'react';
import { Input, Dropdown, Button, Label } from 'semantic-ui-react';
import { auth, firestore } from '../fire';
import './index.css'
import PowerdBy from '../assets/powerdbyGray.png'
import TestPowerdBy from '../assets/footer.jpeg'


import Strings from '../strings';
import { Link } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { useHistory } from 'react-router-dom'

const options = [
    { key: '971', text: '971', value: '971' },
    { key: '968', text: '968', value: '968' },
    { key: '20', text: '20', value: '20' },
]
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function autinticateMobile(number, counterCode) {
    var appVerifier = window.recaptchaVerifier;
    let optimizedNumber = number.replace(/ /g, '')
    return new Promise((resolve, reject) => {
        auth().signInWithPhoneNumber(('+' + counterCode + optimizedNumber), appVerifier)
            .then(function (confirmationResult) {
                window.confirmationResult = confirmationResult;
                resolve({ success: true, loading: false })
                return 'sucess'
            }).catch(function (error) {
                alert(error.message)
                resolve({ error: error })
            });
    })
    // auth().settings.appVerificationDisabledForTesting = true;


}
function verifyCode(code,history) {

    if (!code) {
        return alert('Please Enter Code sent to you by SMS')
    }
    return new Promise((resolve, reject) => {
        window.confirmationResult.confirm(code).then(function (result) {
            let uid = result.user.uid
            firestore().collection('users').doc(uid).get().then(userObject => {
                if (userObject.exists && userObject.data().firstName) {
                    history.replace('/')
                    resolve({ success: true, loading: false })
                } else {
                    resolve({ step: 3, uid })
                }
            })
            // firestore().collection('users').doc(uid).set({
            //     number: number,
            //     counterCode: counterCode,
            // }, { merge: true }).then(() => {
            //     resolve({ success: true, loading: false })
            // })
        }).catch(function (error) {
            alert(error.message)
            resolve({ error: error.message, loading: false })
        });
    })


}

function Login(props) {
    let [countryCode, setCountryCode] = useState('968');
    let [number, setNumber] = useState('');
    let [step, setStep] = useState(1);
    let [pin, setPin] = useState(false);
    let [loading, setLoading] = useState(false)
    let [name, setName] = useState('')
    let [gender, setGender] = useState('Male')
    let [email, setEmail] = useState('')
    let [dob, setDob] = useState(Date.now())
    let [user, setUser] = useState(false)
    const history = useHistory()
    
    useEffect(() => {

        if (window.document.getElementById('recaptcha-container')) {
            window.recaptchaVerifier = new auth.RecaptchaVerifier('recaptcha-container', {
                'size': 'invisible',
                'callback': function (response) {
                }
            });
            try {
                window.recaptchaVerifier.render()
            } catch (error) {
                console.log("errorerror", error)
            }
        }
    }, [])

    return (
        <div className='login' >
            <div id="recaptcha-container"></div>
            <img className='login-logo' src={`${process.env.REACT_APP_NAME}/logo.png`} />
            {
                step === 1 ?
                    <div className='login-card'>
                        <div className='login-title'>{Strings.login}</div>
                        <div className='login-input'>
                            <Input
                                type='number'
                                key='number'
                                onChange={(e, { value }) => setNumber(value)}
                                label={<Dropdown
                                    style={{ display: 'flex', alignItems: 'center', width: '80px' }}
                                    defaultValue={countryCode}
                                    onChange={(e, { value }) => setCountryCode(value)}
                                    options={options} />}
                                labelPosition='left'
                                style={{ height: '50px', direction: 'ltr' }} fluid />
                        </div>
                        <div>
                            <Button fluid loading={loading} className='signin-btn' onClick={() => {
                                setLoading(true)
                                autinticateMobile(number, countryCode).then(({ success, loading }) => {
                                    setLoading(false)
                                    if (success) {
                                        setStep(2)
                                    }
                                })
                            }}>
                                {Strings.login}
                            </Button>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to='/' style={{ textAlign: 'center', padding: '12px 0', color: 'var(--themeColor)', cursor: 'pointer' }}>{Strings["Continue as a guest"]}</Link>

                        </div>
                    </div>
                    : step === 2 ?
                        <div className='login-card'>
                            <div className='login-title'>{Strings.verifyCode}</div>
                            <div className='login-input'>
                                <Input
                                    className='verify_input'
                                    key='verify'
                                    type='number'
                                    style={{ textAlign: 'center' }}
                                    onChange={(e, { value }) => setPin(value)}
                                    style={{ height: '50px' }} fluid />
                            </div>
                            <div>
                                <Button fluid loading={loading} className='signin-btn' onClick={() => {
                                    setLoading(true)
                                    verifyCode(pin,history).then(({ step, uid, success }) => {
                                        setLoading(false)
                                        if (step) {
                                            setUser({ uid, number, countryCode })
                                            setStep(3)
                                        }
                                    })
                                }}>
                                    {Strings.verify}
                                </Button>
                            </div>

                        </div>
                        : step === 3 ?
                            <div className='login-card' style={{ color: "#4d4d4d" }}>
                                <div className='login-title' style={{ padding: '2px 8px' }}>{Strings.Profile}</div>
                                <div style={{ fontSize: '10px', padding: '0 8px', fontFamily: 'tfLight' }}>{Strings['one last step to upgrade your experince']}</div>
                                <div style={{ padding: '8px 0' }}>
                                    <div style={{ padding: '4px 0', fontFamily: "tfMedium" }}>{Strings['Full Name']}</div>
                                    <Input className='profileInput' type='name' onChange={(e, { value }) => setName(value)} fluid />
                                </div>
                                <div style={{ padding: '8px 0' }}>
                                    <div style={{ padding: '4px 0', fontFamily: "tfMedium" }}>{Strings['Email Address']}</div>
                                    <Input className='profileInput' type='email' onChange={(e, { value }) => setEmail(value)} fluid />
                                </div>
                                <div style={{ padding: '8px 0' }}>
                                    <div style={{ padding: '4px 0', fontFamily: "tfMedium" }}>{Strings.Gender}</div>
                                    <Dropdown
                                        search
                                        value={gender}
                                        onChange={(e, { value }) => setGender(value)}
                                        style={{ textAlign: 'initial' }}
                                        className='profileInput'
                                        selection
                                        options={[{ key: 'male', value: 'Male', text: 'Male' }, { key: 'female', value: 'Female', text: 'Female' }]}
                                        fluid />
                                </div>
                                <div style={{ padding: '8px 0' }}>
                                    <div style={{ padding: '4px 0', fontFamily: "tfMedium" }}>{Strings['Date Of Birth']}</div>
                                    <DatePicker showYearDropdown onChange={(date) => setDob(date)} className='datePicker' style={{ width: '100%' }} selected={dob} />
                                </div>
                                <div style={{ padding: '12px 0' }}>
                                    <Button
                                        disabled={!name || !gender || !email || !dob || !validateEmail(email)}
                                        fluid
                                        loading={loading}
                                        className='signin-btn' onClick={() => {
                                            let namesArray = name.split(' ')
                                            let firstName = namesArray?.slice(0, 1)?.join("");
                                            let lastName = namesArray?.slice(1)?.join("");
                                            let optimizedDOB = moment(dob).format('MMM DD, yyyy');
                                            firestore().collection('users').doc(user?.uid).set({ ...user, firstName: firstName, lastName: lastName, birthday: optimizedDOB, email: email, gender: gender }, { merge: true }).then(() => {
                                                history.replace('/')

                                            })
                                        }}>
                                        {Strings.submit}
                                    </Button>
                                </div>
                            </div>
                            : ''
            }
            {
                process.env.REACT_APP_NAME !== 'doctory' ?
                    <Link to={{ pathname: 'https://sinou.io' }} target="_blank">
                        <img style={{ width: '50%' }} src={TestPowerdBy} />

                    </Link>
                    :
                    <img style={{ width: '50%', padding: '20px' }} src={PowerdBy} />
            }
        </div>
    );
}

export default Login;