import React, { Component } from 'react';
import Header from './header/header'
import About from './about/about'
import Specs from './specs/specs'
import Timings from './timings/timings'
import TagsView from './tagView/tagView'
import './index.css'
import { connect } from "react-redux";
import { Icon, Modal, Button } from 'semantic-ui-react';
import { firestore, auth } from '../fire';
import Strings from '../strings';
import { Link, Redirect } from 'react-router-dom'
var lastScrollTop = 0
function getNumber(clinic) {
    if (!clinic) {
        return ''
    }
    if (clinic.tollNumber && clinic.tollNumber.length) {
        let number = clinic.tollNumber[0]
        return (number?.country.replace('UAE', '').replace('OMR', '') + number?.code + number?.number)
    }
    if (clinic.landLineNumbers && clinic.landLineNumbers.length) {
        let number = clinic.landLineNumbers[0]
        return (number?.country.replace('UAE', '').replace('OMR', '') + number?.code + number?.number)
    }
}
class index extends Component {
    state = {}
    componentDidMount() {
        let facilityId = new URL(window.location.href).searchParams.get('key')
        if (facilityId) {
            firestore().collection('clinics').doc(facilityId).update({
                views: firestore.FieldValue.increment(1)
            }).catch(err => {
                console.log('err')
            })
        }
        window.addEventListener("scroll", this.detectScroll, false);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    detectScroll(e) {
        let element = document.getElementById('facility_actions')
        const bottom = ((document.body.scrollHeight - 200) <= (document.body.clientHeight + lastScrollTop) && lastScrollTop > 50)
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (((st > lastScrollTop) || bottom) && element) {
            // downscroll code
            element.style.display = 'none'
        } else {
            element.style.display = 'flex'
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.detectScroll)
    }
    render() {
        let { announce, loginPopup } = this.state
        let { providers } = this.props
        let facilityId = new URL(window.location.href).searchParams.get('key')
    
        let facility = providers.facilities.find(item => item.facilityId === facilityId)
        if (!facility) {
            return (
                <Redirect to='/' />
            )
        }
        let doctors = providers.providers.filter(item => Object.keys(item.clinics).includes(facility.facilityId) && facility.doctors?.includes(item.id))
        let services = []
        services = services.concat(...doctors.map(item => Object.keys(item.services)));
        services = [...new Set(services)];

        let activeServices = services?.map(item => {
            return providers.services.find(rec => rec.id === item)
        })
        let activeInsurnces = Object.keys(facility?.selectedInsurances).map(insuKey => {
            return providers.insurnces.find(rec => rec.id === insuKey)
        })
        let activeAmenities = facility?.amenities?.map(key => {
            return providers?.amenities.find(rec => rec.id === key)
        })
        let lang = localStorage.getItem('lang')
        return (
            <div className='facility'>
                <div className='App-header' style={{ backgroundColor: 'var(--themeColor)' }}>
                    <div style={{ color: '#fff' }} onClick={() => window.history.back()}>
                        <Icon style={{ fontSize: '22px' }} name={lang === 'ar' ? 'arrow right' : 'arrow left'}></Icon>
                    </div>
                </div>
                <Header facility={facility} />
                <About facility={facility} />
                <Specs facility={facility} providers={providers} activeDoctors={doctors} />
                <Timings facility={facility} />
                {
                    activeServices.length ? <TagsView title="Services" tags={activeServices} /> : ''

                }
                {
                    activeInsurnces?.length ? <TagsView title="Insurances" tags={activeInsurnces} />
                        : ''

                }
                {
                    activeAmenities ?
                        <TagsView title='Amenities' tags={activeAmenities} />
                        : ''
                }
                {
                    announce && <div className='announce'>
                        <div style={{ backgroundColor: '#00000060', padding: '20px', borderRadius: '8px', fontFamily: 'tfMedium', fontSize: '18px', color: '#fff' }}>
                            Link Copied to your clipboard!
                        </div>


                    </div>
                }
                {
                    this.footer(facility, doctors)
                }
                {
                    loginPopup && this.renderConfirmPopup()
                }

            </div>
        );
    }
    footer(facility, doctors) {
        let userUID = auth().currentUser?.uid
        let liked = false
        let recommended = false
        if (userUID) {
            liked = facility?.favorite?.stars && Object.keys(facility?.favorite?.stars).includes(userUID)
            recommended = facility?.recommend?.stars && Object.keys(facility?.recommend?.stars).includes(userUID)
        }
        let bookingEnabled = facility.bookingActive && (doctors.filter(rec => rec?.bookingActive && rec?.bookingActive[facility.facilityId]).length > 0)
        return (
            <div id='facility_actions' className='facility_actions'>
                <div className='btn' onClick={() => {
                    navigator.clipboard.writeText(window.location.href)

                    this.setState({ announce: true }, () => {
                        setTimeout(() => {
                            this.setState({ announce: false })
                        }, 3000)
                    })
                }}>
                    <Icon className='btn' style={{ color: 'gray' }} name='share square outline' />
                </div>
                <div className='btn'>
                    <Icon onClick={() => this.like(liked)} className='btn' style={{ color: 'red' }} name={liked ? 'heart' : 'heart outline'} />
                </div>
                {
                    bookingEnabled ?
                        <div className='btn' style={{ backgroundColor: 'var(--secondaryColor)', borderRadius: '22px', width: '180px' }}>
                            <Link to={`/book?selectDoctor=${facility.facilityId}`} style={{ fontSize: '16px', color: '#fff', fontFamily: 'tfBold' }}>{Strings.book}</Link>
                        </div>
                        :
                        <div className='btn' style={{ backgroundColor: '#77C344', borderRadius: '22px', width: '140px' }}>
                            <a href={`tel:${getNumber(facility)}`} style={{ fontSize: '18px', color: '#fff', fontFamily: 'tfBold' }}>{Strings.call}</a>
                        </div>
                }

            </div>
        )

    }
    like(flag) {
        if (auth().currentUser) {
            let facilityId = new URL(window.location.href).searchParams.get('key')
            firestore().collection('clinics').doc(facilityId).update({
                'favorite.starCount': firestore.FieldValue.increment(flag ? -1 : 1),
                [`favorite.stars.${auth().currentUser.uid}`]: flag ? firestore.FieldValue.delete() : true
            })
        } else {
            this.setState({ loginPopup: true })
        }

    }
    renderConfirmPopup() {
        return (
            < Modal open={true} style={{ textAlign: 'center' }}>
                <Modal.Header>{'Alert'}</Modal.Header>
                <Modal.Content>
                    {

                        <div>{Strings['You have to login to start using this feature']}</div>
                    }
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={() => this.setState({ loginPopup: false })}>{Strings["Cancel"]}</Button>
                    <Button color='blue' onClick={() => {
                        window.history.pushState(null, null, '/login')
                        window.history.go()
                    }
                    }>{Strings.login}</Button>
                </Modal.Actions>

            </Modal >
        )

    }
}
const mapStateToProps = ({ providers }) => {
    return {
        providers,
    };
};
export default connect(mapStateToProps, {})(index);
