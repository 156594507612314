import React, { Component } from 'react';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from 'react-dropzone'
import { auth, firestore, storage } from '../fire';
import Resizer from "react-image-file-resizer";
import moment from 'moment';
import Strings from '../strings';

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            "PNG",
            100,
            0,
            (uri) => {
                let optimizedURl = uri.replace('data:image/jpeg;', '')
                resolve(uri);
            },
            "base64"
        );
    });
function translateInsurnce(key, insurnces) {
    let insu = insurnces.find(rec => rec.id === key)
    if (insu) {
        return insu.name
    }
    return '--'
}
function getNetworks(key, insurnces) {
    let selectedInsurnce = insurnces.find(rec => rec.id === key)
    if (selectedInsurnce) {
        return selectedInsurnce?.networks.map(item => ({
            key: item,
            value: item,
            text: item
        }))
    }
    return []

}
class Insurence extends Component {
    state = {
        startDate: Date.now(),
        addInsurance: this.props.newInsurance || false
    }
    updateInsurnce = async () => {
        let { user, dismiss } = this.props
        let { selectedInsurence, startDate, selectedNetwork, cardBack, cardFront, policy, addInsurance, staffNumber, companyName } = this.state;
        let downloadUrl = {};
        if (!selectedInsurence || !startDate || !policy) {
            return
        }

        if (cardBack) {
            let resizedImg = await resizeFile(cardBack)
            let uploadOperation = await storage().ref().child('users').child(auth().currentUser.uid).child('userInsurances').child(selectedInsurence.name).child('backImage').putString(resizedImg, 'data_url', { contentType: 'image/jpg' })
            downloadUrl.back = await uploadOperation.ref.getDownloadURL()

        }
        if (cardFront) {
            let resizedImg = await resizeFile(cardFront)
            let uploadOperation = await storage().ref().child('users').child(auth().currentUser.uid).child('userInsurances').child(selectedInsurence.name).child('frontImage').putString(resizedImg, 'data_url', { contentType: 'image/jpg' })
            downloadUrl.front = await uploadOperation.ref.getDownloadURL()
        }
        let post = {
            expiryDate: moment(startDate).format('MMM DD, yyyy'),
            insurance: selectedInsurence.id,
            network: selectedNetwork || '',
            policyNumber: policy
        }
        if (cardFront) {
            post.frontImage = downloadUrl.front
        } else if (addInsurance?.frontImage) {
            post.frontImage = addInsurance.frontImage
        }
        if (cardBack) {
            post.backImage = downloadUrl.back
        } else if (addInsurance?.backImage) {
            post.backImage = addInsurance.backImage
        }
        let newInsurances = user.insurances ? user.insurances : []
        if (newInsurances && newInsurances.length) {
            newInsurances = newInsurances.filter(rec => rec.insurance !== selectedInsurence.id)
        }
        if (companyName) {
            post.companyName = companyName
        }
        if (staffNumber) {
            post.staffNumber = staffNumber
        }
        newInsurances.push(post)
        firestore().collection('users').doc(auth().currentUser.uid).update({
            insurances: newInsurances
        }).then(() => {

            this.setState({
                selectedInsurence: false,
                selectedNetwork: false,
                cardBack: false,
                cardFront: false,
                addInsurance: false,
                staffNumber: false,
                companyName: false
            }, () => {
                if (dismiss) {
                    dismiss()
                }
            })
        })
    }
    updateID = async () => {
        let { cardBack, cardFront, addID } = this.state
        if (!cardBack || !cardFront) {
            return
        }
        let downloadUrl = {}
        if (cardBack) {
            let resizedImg = await resizeFile(cardBack)
            let uploadOperation = await storage().ref().child('users').child(auth().currentUser.uid).child('emiratesID').child('backImage').putString(resizedImg, 'data_url', { contentType: 'image/jpg' })
            downloadUrl.backImage = await uploadOperation.ref.getDownloadURL()

        } else if (addID.backImage) {
            downloadUrl.backImage = addID.backImage
        }
        if (cardFront) {
            let resizedImg = await resizeFile(cardFront)
            let uploadOperation = await storage().ref().child('users').child(auth().currentUser.uid).child('emiratesID').child('frontImage').putString(resizedImg, 'data_url', { contentType: 'image/jpg' })
            downloadUrl.frontImage = await uploadOperation.ref.getDownloadURL()
        } else if (addID.frontImage) {
            downloadUrl.frontImage = addID.frontImage
        }
        firestore().collection('users').doc(auth().currentUser.uid).update({
            emiratesID: downloadUrl
        }).then(() => {
            this.setState({
                addID: false,
                cardFront: false,
                cardBack: false
            })
        })

    }
    renderAddID() {
        let { cardFront, cardBack, addID } = this.state
        return (
            < div className='add_insurnce' >
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px 8px', justifyContent: 'space-between', fontFamily: 'tfMedium' }}>
                    <div onClick={() => this.setState({ addID: false })} style={{ color: 'var(--themeColor)', width: '40px' }}>{Strings.Cancel} </div>
                    <div style={{ fontSize: '16px' }}>{Strings['Add ID']}</div>
                    <div onClick={() => this.setState({ addID: false })} style={{ color: cardFront && cardBack ? 'var(--themeColor)' : '#cacaca', width: '40px' }} onClick={() => this.updateID()}>{Strings.Save}</div>
                </div>
                <div style={{ padding: '8px 12px' }}>
                    <div>
                        <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '18px' }}>{Strings['Upload Personal ID Card']}</div>
                        <div style={{ fontSize: '11px', fontFamily: 'tfThin' }}>{Strings['*upload Personal card with good quality']} </div>
                    </div>
                    <div className='insurance_card' style={{ backgroundImage: `url(${cardFront?.prev || addID.frontImage})` }} >

                        <Dropzone
                            onDrop={acceptedFiles => this.setState({
                                cardFront: Object.assign(acceptedFiles[0], {
                                    prev: URL.createObjectURL(acceptedFiles[0])
                                })
                            })
                            }
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{Strings['Upload Personal card [Front]']}</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                    </div>
                    <div className='insurance_card' style={{ backgroundImage: `url(${cardBack?.prev || addID.backImage})` }}>

                        <Dropzone onDrop={acceptedFiles => this.setState({
                            cardBack: Object.assign(acceptedFiles[0], {
                                prev: URL.createObjectURL(acceptedFiles[0])
                            })
                        })}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{Strings['Upload Personal card [Back]']} </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
            </div >
        )

    }
    renderAddInsurnce() {
        let { insurnces, dismiss, user } = this.props
        let { selectedInsurence, startDate, cardBack, cardFront, selectedNetwork, policy, addInsurance, companyName, staffNumber } = this.state
        let optimizedInsurences = insurnces?.map(rec => ({
            key: rec.id,
            text: rec.name,
            value: rec.name,
        }))
        return (
            <div className='add_insurnce'>
                <div style={{ display: 'flex', alignItems: 'center', padding: '16px 8px', justifyContent: 'space-between', fontFamily: 'tfMedium' }}>
                    <div onClick={() => {
                        if (dismiss) {
                            dismiss()
                        };
                        this.setState({
                            addInsurance: false,
                            selectedInsurence: false,
                            policyNumber: false,
                            staffNumber: false,
                            companyName: false
                        });
                    }} style={{ color: 'var(--themeColor)', width: '40px' }}>{Strings.Cancel}</div>
                    <div style={{ fontSize: '16px' }}> {Strings['Add Insurance']}</div>
                    <div
                        style={{ color: selectedInsurence && startDate && policy ? 'var(--themeColor)' : '#cacaca', width: '40px' }}
                        onClick={() => this.updateInsurnce()}>{Strings.Save}</div>
                </div>
                <div style={{ padding: '18px' }}>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Insurance Company*']} </div>
                        <Dropdown value={selectedInsurence ? selectedInsurence.name : ''} onChange={(e, { value }) => this.setState({ selectedInsurence: insurnces.find(rec => rec.name === value) })} search selection fluid className='add_input' transparent icon='' options={optimizedInsurences} />
                    </div>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Insurance Plan*']}</div>
                        <Dropdown value={selectedNetwork} onChange={(e, { value }) => this.setState({ selectedNetwork: value })} search selection fluid className='add_input' transparent options={getNetworks(selectedInsurence?.id, insurnces)} icon='' />
                    </div>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Policy Number*']} </div>
                        <Input fluid className='add_input' value={policy} transparent onChange={(e, { value }) => this.setState({ policy: value })} />
                    </div>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Expiration Date*']}</div>
                        <DatePicker style={{ width: '100%' }} onChange={(date) => this.setState({ startDate: date })} selected={startDate} className='add_input' />
                    </div>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Company Name']}</div>
                        <Input fluid className='add_input' value={companyName ? companyName : ''} transparent onChange={(e, { value }) => this.setState({ companyName: value })} />

                    </div>
                    <div className='insu_row'>
                        <div className='add_head'>{Strings['Staff Number']}</div>
                        <Input fluid className='add_input' value={staffNumber ? staffNumber : ''} transparent onChange={(e, { value }) => this.setState({ staffNumber: value })} />

                    </div>

                </div>
                <div style={{ padding: '0 12px' }}>
                    <div>
                        <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '18px' }}>{Strings['Upload insurance Card']}</div>
                        <div style={{ fontSize: '11px', fontFamily: 'tfThin' }}>{Strings['*upload insurance card with good quality']} </div>
                    </div>
                    <div className='insurance_card' style={{ backgroundImage: `url(${cardFront?.prev || addInsurance.frontImage})` }} >

                        <Dropzone
                            onDrop={acceptedFiles => this.setState({
                                cardFront: Object.assign(acceptedFiles[0], {
                                    prev: URL.createObjectURL(acceptedFiles[0])
                                })
                            })
                            }
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{Strings['Upload Insurance card [Front]']}</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>

                    </div>
                    <div className='insurance_card' style={{ backgroundImage: `url(${cardBack?.prev || addInsurance.backImage})` }}>

                        <Dropzone onDrop={acceptedFiles => this.setState({
                            cardBack: Object.assign(acceptedFiles[0], {
                                prev: URL.createObjectURL(acceptedFiles[0])
                            })
                        })}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>{Strings['Upload Insurance card [Back]']} </p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                </div>
                {
                    console.log("typeof (addInsurance)typeof (addInsurance)", typeof (addInsurance))
                }
                {
                    typeof (addInsurance) !== 'boolean' ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                color='red'
                                style={{ margin: '8px' }}
                                onClick={() => {
                                    firestore().collection('users').doc(user.uid).update({
                                        insurances: firestore.FieldValue.arrayRemove(addInsurance)
                                    }).then(() => {
                                        this.setState({ addInsurance: false, selectedInsurence: false, policyNumber: false })
                                    })
                                }}
                                fluid>
                                {Strings.delete}</Button>
                        </div>
                        : ''
                }

            </div>
        )
    }
    render() {
        let { user } = this.props
        let { insurances } = user || {};
        let { addInsurance, addID } = this.state
        return (
            <div>

                {
                    addInsurance ?
                        this.renderAddInsurnce()
                        : addID ?
                            this.renderAddID()
                            :
                            <>
                                <div style={{ padding: '12px 8px' }}>
                                    <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '22px' }}>{Strings.Insurance}</div>
                                    <div style={{ padding: '8px 0' }}>
                                        {
                                            insurances && insurances.map(item => {
                                                return (
                                                    this.recnderRecord(item, 'insurance')
                                                )
                                            })
                                        }
                                        <div onClick={() => this.setState({ addInsurance: true })} style={{ padding: '8px 0', color: 'var(--themeColor)', cursor: 'pointer' }}>
                                            {Strings["+Add Insurance"]}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ padding: '12px 8px' }}>
                                    <div style={{ fontFamily: 'tfBold', color: '#4d4d4d', fontSize: '22px' }}>{Strings["Personal ID"]}</div>
                                    <div style={{ padding: '8px 0' }}>
                                        {

                                            user?.emiratesID ? this.recnderRecord(user.emiratesID, 'ID')
                                                :
                                                <div onClick={() => this.setState({ addID: true })} style={{ padding: '8px 0', color: 'var(--themeColor)', cursor: 'pointer' }}>
                                                    {Strings["+Add your ID"]}
                                                </div>
                                        }

                                    </div>
                                </div>
                            </>


                }



            </div>
        );
    }
    recnderRecord(item, type) {
        let { user, insurnces } = this.props
        return (
            <div key={item?.insurance}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#cacaca30',
                    borderRadius: '8px',
                    padding: '12px',
                    margin: '8px 0'
                }}>
                <div >
                    <div style={{ color: 'var(--themeColor)', fontFamily: 'tfMedium' }}>{type === 'insurance' ? translateInsurnce(item.insurance, insurnces) : "Personal ID"}</div>
                    <div style={{ color: '#cacaca' }}>{item?.expiryDate}</div>
                </div>
                <div onClick={() => {
                    if (type === 'insurance') {
                        let selectedInsurence = insurnces.find(rec => rec.id === item.insurance)
                        this.setState({
                            addInsurance: item,
                            selectedInsurence,
                            selectedNetwork: item.network,
                            policy: item.policyNumber,
                            staffNumber: item?.staffNumber,
                            companyName: item.companyName
                        })
                    } else {
                        this.setState({ addID: user?.emiratesID })
                    }
                }
                } style={{ color: 'var(--themeColor)', fontFamily: 'tfMedium', cursor: 'pointer' }}>{Strings.Edit} </div>
            </div>
        )
    }
}

export default Insurence;